"use client";

import Link from "next/link";
import HamburgerButton from "@/layouts/hamburger-button";
import Sidebar from "@/layouts/hydrogen/sidebar";
import Logo from "@components/logo";
import HeaderMenuRight from "@/layouts/header-menu-right";
import StickyHeader from "@/layouts/sticky-header";
import { useTranslation } from "@/app/i18n/client";
import SearchWidget from "@/app/shared/search/search";
import { useSidebar } from "@/layouts/hydrogen/sidebar-context";
import { Menu } from "lucide-react";
import { useDirection } from '@hooks/use-direction';

export default function Header({ lang }: { lang?: string }) {
  const { t } = useTranslation(lang!, "common");
  const { isMobileView, toggleSidebar } = useSidebar();
  const { direction } = useDirection();

  return (
    <StickyHeader className={`z-[990] 2xl:py-5 3xl:px-8 4xl:px-10`}>
      <div className={`flex w-full items-center ${direction === 'rtl' ? 'flex-row-reverse' : ''}`}>
        <div className="flex items-center flex-grow">
          {isMobileView ? (
            <button
              onClick={toggleSidebar}
              className={`p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors ${direction === 'rtl' ? 'ml-4' : 'mr-4'}`}
            >
              <Menu size={24} />
            </button>
          ) : (
            <HamburgerButton
              view={<Sidebar className="static w-full 2xl:w-full" lang={lang} />}
            />
          )}
          <Link
            href={"/"}
            aria-label="Site Logo"
            className={`${direction === 'rtl' ? 'ms-4 lg:ms-5' : 'me-4 lg:me-5'} w-9 shrink-0 text-gray-800 hover:text-gray-900 xl:hidden`}
          >
            <Logo iconOnly={true} />
          </Link>

          {/* <SearchWidget t={t} /> */}
        </div>

        <HeaderMenuRight lang={lang} />
      </div>
    </StickyHeader>
  );
}
