import React from 'react';

interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
  iconOnly?: boolean;
}

export default function Logo({ iconOnly = false, className, style, ...props }: LogoProps) {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: iconOnly ? '64px' : '64px', // Adjust this height to match your sidebar item height
    ...style
  };

  const svgStyle: React.CSSProperties = {
    width: iconOnly ? '32px' : '28px',
    height: iconOnly ? '32px' : '28px',
  };

  return (
    <div className={className} style={containerStyle} {...props}>
      <svg 
        viewBox="0 0 310 258" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        style={svgStyle}
      >
        <path d="M0 257.025L23.164 235.9C23.164 235.816 23.142 235.743 23.142 235.659C23.142 223.743 32.346 214.08 44.025 213.144C42.814 210.511 42.088 207.605 42.088 204.508C42.088 197.869 45.268 192.029 50.144 188.248C47.985 176.832 50.578 161.348 58.265 143.624C74.409 106.403 104.282 102.344 125.976 87.448C138.856 78.612 144.361 69.799 148.9 64.075V0H0V257.025Z" fill="#FCC10A"/>
        <path d="M162.485 64.065C167.15 69.793 173.945 79.028 186.223 87.448C207.915 102.343 237.79 106.403 253.933 143.624C261.622 161.352 264.214 176.832 262.066 188.248C266.931 192.029 270.122 197.869 270.122 204.508C270.122 207.604 269.385 210.511 268.174 213.144C279.851 214.08 289.057 223.743 289.057 235.657C289.057 235.975 288.982 236.268 288.971 236.569L289.057 235.657L309.823 257.649V164.189V0H162.485V64.065Z" fill="#601647"/>
        <path d="M226.396 152.601C215.759 128.085 190.33 125.411 176.039 115.596C161.759 105.797 156.2 95.988 156.2 95.988C156.2 95.988 150.628 105.797 136.348 115.596C122.059 125.411 96.628 128.085 85.992 152.601C75.356 177.117 75.783 212.471 75.783 212.471C75.783 212.471 110.232 204.908 156.358 204.908C202.482 204.908 239.567 212.471 239.567 212.471C239.567 212.471 237.034 177.117 226.396 152.601Z" fill="#FCC10A"/>
      </svg>
      {!iconOnly && (
        <span style={{ fontSize: '24px', fontWeight: 'bold', color: 'currentColor', marginLeft: '10px' }}>
          Allotment
        </span>
      )}
    </div>
  );
}