"use client";

import Link from "next/link";
import cn from "@utils/class-names";
import SimpleBar from "@ui/simplebar";
import Logo from "@components/logo";
import { SidebarMenu } from "./sidebar-menu";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useSidebar } from '@/layouts/hydrogen/sidebar-context';

export default function Sidebar({
  className,
  lang,
}: {
  className?: string;
  lang?: string;
}) {
  const { isCollapsed, isMobileView, toggleSidebar } = useSidebar();

  return (
    <aside
      className={cn(
        "fixed bottom-0 start-0 z-50 h-full border-e-2 border-gray-100 bg-white dark:bg-gray-100/50 transition-all duration-300 ease-in-out",
        isCollapsed ? "w-[60px]" : "w-[270px] 2xl:w-72",
        isMobileView && (isCollapsed ? "-translate-x-full" : "translate-x-0"),
        className
      )}
    >
      <div className="sticky top-0 z-40 bg-gray-0/10 px-3 pb-3 pt-3 dark:bg-gray-100/5">
        <div className="flex items-center">
          <Link
            href={"/"}
            aria-label="Site Logo"
            className="flex items-center justify-center"
          >
            {isCollapsed ? (
              <Logo iconOnly className="w-8 h-8" />
            ) : (
              <Logo className="w-[155px] h-8" />
            )}
          </Link>
          {!isCollapsed && (
            <button
              onClick={toggleSidebar}
              className="ml-auto p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            >
              <ChevronLeft size={20} />
            </button>
          )}
        </div>
      </div>

      <SimpleBar className="h-[calc(100%-80px)]">
        <SidebarMenu lang={lang} isCollapsed={isCollapsed} />
      </SimpleBar>

      {isCollapsed && (
        <div className="absolute bottom-4 left-0 right-0 flex justify-center">
          <button
            onClick={toggleSidebar}
            className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}

      {isMobileView && (
        <button
          onClick={toggleSidebar}
          className="absolute top-4 right-4 p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
        >
          <ChevronLeft size={20} />
        </button>
      )}
    </aside>
  );
}
