export default function ParcelMapIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m5.792 10.638 1.451.565-4.298 1.671-1.565-.609 4.185-1.627c.076-.03.15-.03.227 0ZM8.618.395a2.777 2.777 0 0 1 2.777 2.776c0 2.478-2.777 4.668-2.777 4.668S5.842 5.649 5.842 3.17A2.777 2.777 0 0 1 8.618.395Zm0 1.107a1.67 1.67 0 1 0 0 3.34 1.67 1.67 0 0 0 0-3.34Zm1.61 5.643c-.13.147-.265.294-.405.438.157.053.248.115.248.183 0 .18-.65.327-1.453.327-.802 0-1.452-.146-1.452-.327 0-.068.09-.13.247-.183-.14-.144-.275-.291-.404-.438-.872.129-1.453.359-1.453.621 0 .403 1.371.73 3.062.73 1.222 0 2.277-.17 2.768-.417h5.356c.588 0 1.07.482 1.07 1.07 0 .587-.482 1.07-1.07 1.07h-.84a1.7 1.7 0 0 0-1.694 1.694 1.7 1.7 0 0 0 1.695 1.695h1.01c.83 0 1.511.681 1.511 1.512s-.68 1.512-1.511 1.512h-6.507v-3.865l-4.415 1.717v5.125l4.216-1.64c.123-.047.2-.16.2-.29v-.422h6.506a2.142 2.142 0 0 0 2.136-2.137 2.142 2.142 0 0 0-2.136-2.137h-1.01c-.588 0-1.07-.482-1.07-1.07 0-.587.482-1.07 1.07-1.07h.84a1.7 1.7 0 0 0 1.694-1.694 1.7 1.7 0 0 0-1.695-1.695h-5.356c-.253-.128-.658-.235-1.159-.309ZM8.84 11.824l-4.298 1.671 1.135.442 4.299-1.671-1.136-.442Zm-3.475 7.785v-5.125l-1.253-.487v.978a.063.063 0 0 1-.103.048l-.73-.622-.683.213a.063.063 0 0 1-.082-.06v-1.179L.95 12.767v4.911c0 .132.077.244.2.291l4.216 1.64Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
