export default function FileStackIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g fill="currentColor">
        <path d="M7.46 0H4.14c-.753 0-1.367.613-1.367 1.367v3.125c0 .754.614 1.367 1.368 1.367h3.32V0ZM15.86 0H8.632v5.86h7.226c.754 0 1.368-.614 1.368-1.368V1.367C17.227.613 16.613 0 15.859 0Zm-.977 3.516h-3.906a.586.586 0 1 1 0-1.172h3.906a.586.586 0 1 1 0 1.172ZM10.234 14.14h-3.32c-.754 0-1.367.614-1.367 1.368v3.125c0 .754.613 1.367 1.367 1.367h3.32v-5.86ZM18.633 14.14h-7.227V20h7.227c.754 0 1.367-.613 1.367-1.367v-3.125c0-.754-.613-1.367-1.367-1.367Zm-.977 3.516H13.75a.586.586 0 1 1 0-1.172h3.906a.586.586 0 1 1 0 1.172ZM4.688 7.07h-3.32C.612 7.07 0 7.684 0 8.437v3.126c0 .753.613 1.367 1.367 1.367h3.32V7.07ZM13.086 7.07H5.859v5.86h7.227c.754 0 1.367-.614 1.367-1.367V8.437c0-.753-.613-1.367-1.367-1.367Zm-.977 3.516H8.203a.586.586 0 1 1 0-1.172h3.906a.586.586 0 0 1 0 1.172ZM15.875 10.367a.586.586 0 0 0 0 .828l1.562 1.563c.23.229.6.229.829 0l1.562-1.563a.586.586 0 1 0-.828-.828l-.563.562V7.656a.586.586 0 0 0-1.171 0v3.273l-.563-.562a.586.586 0 0 0-.828 0ZM2.563 14.312a.586.586 0 0 0-.829 0L.172 15.875a.586.586 0 0 0 .828.828l.562-.562v3.273a.586.586 0 1 0 1.172 0v-3.273l.563.562a.586.586 0 0 0 .828-.828l-1.562-1.563Z" />
      </g>
    </svg>
  );
}
