"use client";

import Header from '@/layouts/hydrogen/header';
import Sidebar from '@/layouts/hydrogen/sidebar';
import { SidebarProvider, useSidebar } from '@/layouts/hydrogen/sidebar-context';
import cn from '@utils/class-names';

function Layout({
  children,
  lang,
}: {
  children: React.ReactNode;
  lang?: string;
}) {
  const { isCollapsed, isMobileView } = useSidebar();

  return (
    <main className="flex min-h-screen flex-grow">
      <Sidebar className="fixed xl:block dark:bg-gray-50" lang={lang} />
      <div className={cn(
        "flex w-full flex-col transition-all duration-300 ease-in-out",
        !isMobileView && (isCollapsed ? 'xl:ms-[60px]' : 'xl:ms-[270px] 2xl:ms-72')
      )}>
        <Header lang={lang} />
        <div className="flex flex-grow flex-col px-4 pb-6 pt-2 md:px-5 lg:px-6 lg:pb-8 3xl:px-8 3xl:pt-4 4xl:px-10 4xl:pb-9">
          {children}
        </div>
      </div>
    </main>
  );
}

export default function HydrogenLayout(props: { children: React.ReactNode; lang?: string }) {
  return (
    <SidebarProvider>
      <Layout {...props} />
    </SidebarProvider>
  );
}
