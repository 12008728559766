import { Badge, ActionIcon } from "rizzui";
import MessagesDropdown from "@/layouts/messages-dropdown";
import ProfileMenu from "@/layouts/profile-menu";
import SettingsButton from "@/layouts/settings-button";
import RingBellSolidIcon from "@components/icons/ring-bell-solid";
import ChatSolidIcon from "@components/icons/chat-solid";
import LanguageSwitcher from "@/app/i18n/language-switcher";
import { useTranslation } from "@/app/i18n/client";
import { useDirection } from '@hooks/use-direction';

export default function HeaderMenuRight({ lang }: { lang?: string }) {
  const { t } = useTranslation(lang!, 'common');
  const { direction } = useDirection();

  return (
    <div className={`ml-auto flex shrink-0 items-center gap-2 xs:gap-3 xl:gap-4 ${direction === 'rtl' ? 'flex-row-reverse' : ''}`}>
      <LanguageSwitcher
        lang={lang!}
        className={`relative h-[34px] w-[34px] overflow-hidden rounded-full shadow backdrop-blur-md before:absolute before:h-full before:w-full before:-rotate-45 before:rounded-full before:bg-gradient-to-l before:from-orange-dark/25 before:via-orange-dark/0 before:to-orange-dark/0 dark:bg-gray-100 md:h-9 md:w-9 3xl:h-10 3xl:w-10`}
        iconClassName="w-5 h-5 rounded-full"
      />

      <div className="flex items-center gap-2 text-gray-700 xs:gap-3 xl:gap-4">
        {/* Other components */}
        <ProfileMenu lang={lang} />
      </div>
    </div>
  );
}
