export default function BulbSolidIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.602 5.018a4.346 4.346 0 0 1 2.742 4.04c0 2.447-1.525 2.278-1.932 5.523h-2.138v-2.924a.274.274 0 0 0-.547 0v2.924H7.588c-.407-3.245-1.932-3.076-1.932-5.524a4.345 4.345 0 0 1 4.027-4.332L7.397 7.17A.625.625 0 0 0 7.69 8.2l1.354.363L7.83 11.05a.625.625 0 0 0 1.018.701l3.757-4.018a.625.625 0 0 0-.295-1.03l-1.354-.363.647-1.322Zm-1.43 1.678 1.508-3.082a.078.078 0 0 0-.127-.088L7.796 7.544a.078.078 0 0 0 .037.13l1.996.534L8.32 11.29a.078.078 0 0 0 .128.088l3.756-4.018a.079.079 0 0 0-.002-.11.078.078 0 0 0-.035-.019l-1.995-.535ZM7.6 15.128h4.8a.72.72 0 0 1 .719.718v1.55a.72.72 0 0 1-.718.718h-.739v.72a.68.68 0 0 1-.677.678h-1.97a.68.68 0 0 1-.677-.678v-.72H7.6a.72.72 0 0 1-.719-.718v-1.55a.72.72 0 0 1 .719-.718Zm7.085-.998a.274.274 0 0 1 .387-.387l.988.987a.273.273 0 0 1-.09.447.274.274 0 0 1-.297-.06l-.988-.988ZM16.9 9.33a.274.274 0 0 1 0-.547h1.397a.273.273 0 0 1 0 .547H16.9Zm-1.827-4.958a.273.273 0 1 1-.387-.387l.988-.988a.274.274 0 0 1 .387.387l-.988.988Zm-10.144 9.37a.273.273 0 1 1 .387.387l-.988.987a.273.273 0 1 1-.386-.387l.987-.987ZM3.101 8.784a.273.273 0 0 1 0 .547H1.704a.273.273 0 1 1 0-.547h1.397Zm2.214-4.798a.273.273 0 0 1-.387.387l-.987-.988a.273.273 0 1 1 .386-.387l.988.988Zm4.958-1.828a.273.273 0 1 1-.546 0V.762a.273.273 0 0 1 .546 0v1.396Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
