export default function BrushSolidIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g>
        <path
          className="fill-current opacity-40"
          d="M18.52.885C16.95-.317 14.765 3.07 13.23 5.002c-1.828 1.945-4.997 6.41-5.017 6.46-2.56-.594-4.737 2.159-5.714 4.813C2.075 17.428.78 17.485.976 18.11c.516 1.651 4.536 1.94 6.278 1.793 2.452-.494 5.716-3.228 4.153-5.916-.495-.69-1.446-1.669-1.432-1.693.65-.785 1.572-1.681 2.056-2.348 1.526-2.017 2.584-3.539 4.31-5.383.676-1.108 3.185-2.231 2.179-3.678Z"
        />
        <path
          fill="currentColor"
          d="M7.306 6.624c-.094-2.061-3.398-2.929-4.104-.788a2.515 2.515 0 0 0-.11.846c.006.435.689.418.67-.019-.01-.39.064-.749.307-1.027.766-.776 2.272-.209 2.48.82.238 1.26-.899 2.311-2.052 2.603-2.794.845-4.221-2.165-3.151-4.45.65-1.695 2.247-2.863 4.007-3.192 2.292-.472 4.66-.123 6.975.017.417.014.495-.566.093-.665-3.192-.654-6.935-1.39-9.859.526C.403 2.765-.785 5.945.586 8.312c1.51 2.991 6.85 1.678 6.72-1.688ZM19.55 8.035c-.151-.364-.682-.234-.653.156.378 4.314-1.435 9.283-5.951 10.556-.274.08-.736.111-.654.509.043.19.242.3.427.245 4.82-1.09 8.618-6.629 6.831-11.466ZM13.09 11.343a.39.39 0 0 0-.067-.528c-.791-.676-1.611-1.317-2.384-2.017a426.614 426.614 0 0 1 4.884-6.145c.582-.91 1.689-2.073 2.835-1.522 1.084.824.013 2.316-.74 3.002-1.16 1.169-2.352 2.311-3.262 3.705-.429.666.598 1.311 1.01.632.794-1.34 1.918-2.474 2.974-3.62.939-.926 1.821-2.352 1.22-3.695-1.147-2.196-3.972-.516-4.7 1.029-2.312 2.92-4.614 6.083-7.054 8.909-2.13-.286-4.568 1.282-5.309 3.382a.59.59 0 0 0 .09.584c-.48 1.06-.963 2.345-2.154 2.74-.265.085-.302.455-.06.593 2.548 1.517 6.076 2.106 8.675.405 1.542-1.013 2.415-2.99 2.013-4.709.71-.882 1.437-1.789 2.029-2.745Zm-3.086 4.865c-.277.671-.703 1.3-1.278 1.75-1.123.904-2.638 1.158-4.046 1.065a9.375 9.375 0 0 1-3.422-.907c.95-.632 1.404-1.777 1.837-2.834.204-.01.404-.126.514-.38.536-1.376 1.818-2.564 3.305-2.773 1.199-.172 2.334.55 2.916 1.565-.448.421-.911.826-1.395 1.206-.543.43-1.113.819-1.692 1.2a.27.27 0 0 0 .244.477c1.14-.452 2.214-1.057 3.229-1.744.055.458-.005.929-.212 1.375Zm.582-3.231a3.586 3.586 0 0 0-.626-.72 3.45 3.45 0 0 0-1.272-.927c.475-.623.951-1.244 1.43-1.863.197.125.434.229.574.362.544.4 1.031.87 1.52 1.336a16.916 16.916 0 0 1-1.627 1.812Z"
        />
      </g>
    </svg>
  );
}
