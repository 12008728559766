"use client";

import { useTranslation } from "./client";
import { usePathname, useRouter } from "next/navigation";
import useQueryParams from "@hooks/use-query-params";
import { useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import cn from "@utils/class-names";
import { PiCaretDownBold } from "react-icons/pi";
import ReactCountryFlag from "react-country-flag";
import { useDirection } from '@hooks/use-direction';

type LanguageMenuProps = {
  id: string;
  name: string;
  value: string;
  countryCode: string;
};

const languageMenu: LanguageMenuProps[] = [
  {
    id: "en",
    name: "English",
    value: "en",
    countryCode: "US",
  },
  {
    id: "ar",
    name: "العربية",
    value: "ar",
    countryCode: "SA",
  },
  {
    id: "id",
    name: "Bahasa Indonesia",
    value: "id",
    countryCode: "ID",
  },
  {
    id: "uz",
    name: "O'zbek",
    value: "uz",
    countryCode: "UZ",
  },
  {
    id: "hi",
    name: "हिन्दी",
    value: "hi",
    countryCode: "IN",
  },
  {
    id: "ur",
    name: "اردو - UR",
    value: "ur",
    countryCode: "PK",
  },
  {
    id: "tr",
    name: "Türkçe - TR",
    value: "tr",
    countryCode: "TR",
  },
];

export default function LanguageSwitcher({
  lang,
  className,
  iconClassName,
  variant = "icon",
}: {
  lang: string;
  className?: string;
  iconClassName?: string;
  variant?: "text" | "icon";
}) {
  const router = useRouter();
  const pathname = usePathname();
  const { t } = useTranslation(lang);
  const { query } = useQueryParams(pathname ?? "/");
  const options = languageMenu;
  const { direction } = useDirection();

  // Use pathname to determine the current language
  const currentLang = pathname.split('/')[1] || lang;
  const currentSelectedItem = options.find((o) => o.value === currentLang) || options[0];

  const rtlLanguages = ['ar', 'ur']; // Add any other RTL language codes here

  function handleItemClick(values: LanguageMenuProps) {
    const pathnameParts = pathname.split('/');
    pathnameParts[1] = values.value;
    const newPathname = pathnameParts.join('/');
    const pushPathname = `${newPathname}${query}`;

    console.log("direction", direction);
    router.push(pushPathname);
  }

  return (
    <>
      <Listbox value={currentSelectedItem} onChange={handleItemClick}>
        {({ open }) => (
          <div className="relative z-10 lg:top-[1px]">
            <Listbox.Button
              className={cn(
                "relative flex h-[34px] w-14 items-center justify-center rounded-md p-1 shadow backdrop-blur-md transition-colors duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-900/30 focus-visible:ring-opacity-50 hover:enabled:text-gray-1000 active:enabled:translate-y-px dark:bg-gray-100",
                className
              )}
            >
              {variant === "text" ? (
                <span className="block w-full truncate text-left uppercase underline underline-offset-4 rtl:text-right">
                  {t(currentSelectedItem?.value)}
                </span>
              ) : (
                <div className="flex items-center justify-center gap-2 uppercase">
                  <ReactCountryFlag
                    countryCode={currentSelectedItem?.countryCode}
                    svg
                    className={cn(
                      "h-4 w-5 shrink-0 overflow-hidden",
                      iconClassName
                    )}
                  />
                  <PiCaretDownBold className="size-3.5" />
                </div>
              )}
            </Listbox.Button>
            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-50 mt-1 max-h-60 w-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options?.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      `${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}
                      peer relative flex h-10 w-full cursor-pointer select-none items-center py-2 px-4`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <span className="flex w-full items-center justify-between">
                        <span className="flex items-center">
                          <ReactCountryFlag
                            countryCode={option?.countryCode}
                            svg
                            className={`h-4 w-5 flex-shrink-0 mr-3 ml-3`}
                          />
                          <span
                            className={`${
                              selected ? "font-medium" : "font-normal"
                            } block`}
                          >
                            {t(option?.name)}
                          </span>
                        </span>
                        {selected && (
                          <span
                            className="text-amber-600 flex items-center"
                          >
                            ✓
                          </span>
                        )}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </>
  );
}
